import theme from './theme'

export const USER_GUIDE =  'USER_GUIDE'

export const UNIT = {
    PERCENTAGE: "PERCENTAGE", 
    ABSOLUTE: "ABSOLUTE"
}

export const INPUT_UNIT = {
  PERCENT: "%", 
  NUMBER_OF_CHANGES: "Number of Changes",
  NUMBER_OF_TIMES: "Number of Times",
  DAYS: 'Days',
  WEEKS: 'Weeks', 
  HOURS: 'Hours'
}

export const PERIDIOCITY = {
        FIXED: "FIXED",
        MONTHLY : "MONTHLY", 
        YEARLY: "YEARLY", 
        BIANNUAL: "BIANNUAL", 
        QUARTERLY: "QUARTERLY"
}

export const TYPE = {
    KPI: "KPI", 
    RECIPROCAL: "RECIPROCAL_KPI"
}
export const YEARS = [
    { value: new Date().getFullYear(), label: new Date().getFullYear().toString() },
    { value: new Date().getFullYear()-1, label: ((new Date().getFullYear()) - 1).toString() },
    { value: new Date().getFullYear()-2, label: ((new Date().getFullYear()) - 2).toString() },
    { value: new Date().getFullYear()-3, label: ((new Date().getFullYear()) - 3).toString() },
    { value: new Date().getFullYear()-4, label: ((new Date().getFullYear()) - 4).toString() }
]

export const MONTHS = [
    { value: 1, label: 'January' },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
]

export const CALL_COMPLIANCE_OPTIONS = [
    {
      value: 0,
      label: "Deliver to the site without any delay",
    },
    {
      value: 1,
      label: "1 time over 10 working days",
    },
    {
      value: 2,
      label: "2 times over within 10 working days",
    },
    {
      value: 3,
      label: "2 or more times over within 10 working days"
    }
  ]

export const SEMESTERS = [
    { value: 1, label: "First Semester" },
    { value: 2, label: "Second Semester" }
  ]
 
export const PAYMENT_AFTER_ACCURATE_INVOICE_OPTIONS = [
  {
    value: 0,
    label: "As per payment terms in T&C",
  },
  {
    value: 3,
    label: "Zero to five days late",
  },
  {
    value: 8,
    label: "Six to ten days late",
  },
  {
    value: 13,
    label: "Eleven to fifteen days late",
  },
  {
    value: 18,
    label: "Sixteen to twenty days late",
  },
  {
    value: 25,
    label: "More than twenty days late"
  }
] 

export const QUARTERS = [
  { value: 1, label: "First Quarter" },
  { value: 2, label: "Second Quarter" },
  { value: 3, label: "Third Quarter" },
  { value: 4, label: "Fourth Quarter" }
]

export const COLOR_STATUS_MAP = {
  'RED': theme.colors.error,
  'ORANGE': theme.colors.warning,
  'GREEN': theme.colors.success,
  'DEFAULT': '#000000'
}

export const NOT_TOTAL_FACTOR =  0.001
export const HUNDRED =  100

export const STORAGE_TYPE = {
  closedEnd: "Closed End",
  openEnd: "Open End",
  global: "Global", 
  na: "NA"
}

export const CONDITIONS = {
  lessThanSixMonths: { 
    id: 1,
    condition : (time) => time < 6
  },
  betweenSixAndEightMonths: { 
    id: 2, 
    condition : (time) => time >= 6 && time < 8
  },
  betweenEightAndTwelveMonths: {
    id: 3,
    condition : (time) => time >= 8 && time < 12
  },  
  betweenTwelveAndEighteenMonths: { 
    id: 4,
    condition : (time) => time >= 12 && time < 18
  },
  moreThanEighteen: { 
    id: 5,
    condition : (time) => time >= 18
  }
}

export const INTERNAL_REPORTS_TYPE = {
    CONSUMPTION: "CONSUMPTION", 
    STOCK_LEVEL: "STOCK_LEVEL", 
    RECEIPT: "RECEIPT", 
}

export const FILENAMES = {
  RECEIPT_NOTES: 'RN_0000000',
  RECEIVING_INSPECTION: 'R-IR_0000000',
  INSPECTION_REPORT: 'IR_0000000', 
  RIG_PREP: 'RP-IR_0000000',
  DELIVERY_NOTES: 'DN_0000000',
  DISPATCH_REPORT: 'DR_0000000',
  RIG_RETURN: 'RR-IR_0000000',
  MODIFIED_PRODUCTS: 'MP-IR_0000000',
  SPOT_INSPECTION: 'SI-IR_0000000',
  PERIODICAL_INSPECTION: 'PI-IR_0000000', 
  PIPE_AGE: 'PAM_0000000',
  MATERIAL_REQUISITION_DELIVERY_TO_THIRD_PARTY: 'MR-DT3P-IR_0000000',
  MATERIAL_REQUISITION_MATERIAL_PHYSICAL_TRANSFER: 'MR-MPT-IR_0000000',
  MATERIAL_REQUISITION_MATERIAL_MODIFICATION: 'MR-MM-IR_0000000',
  MATERIAL_REQUISITION_RIG_PREP: 'MR-RP-IR_0000000',
  DELIVERY_NOTES_DELIVERY_TO_THIRD_PARTY: 'DN-DT3P-IR_0000000',
  DELIVERY_NOTES_MATERIAL_PHYSICAL_TRANSFER: 'DN-MPT-IR_0000000',
  DELIVERY_NOTES_MATERIAL_MODIFICATION: 'DN-MM-IR_0000000',
  DELIVERY_NOTES_RIG_PREP: 'DN-RP-IR_0000000',
  INVENTORY_BACKUP: 'IB_0000000',
  STOCK_LEVEL_INTERNAL_REPORT: 'SL-IR-',
  CONSUMPTION: 'CSPT-IR-',
  BACKLOAD_MANIFEST: 'RR_0000000'
}

export const REPORT_TYPES = [
  { value: 'MILL_RECEIPT', label: 'Receipt Notes' } ,
  { value: 'RECEIVING_INSP', label: 'Receiving Inspection' } ,
  { value: 'RIG_RETURN_INSP', label: 'Rig Return' },
  { value: 'MODIFIED_PRODUCTS', label: 'Modified Products' },
  { value: 'PERIODICAL_INSP', label: 'Periodical Inspection'} ,
  { value: 'MATERIAL_REQUISITION', label: 'Material Requisition' },
  { value: 'DELIVERY_NOTES', label: 'Delivery Notes'}, 
  { value: 'BACKLOAD_MANIFEST', label: 'Backload Manifest'}, 
  { value: 'PIPE_AGE_REPORTS', label: 'Pipe Age Reports'}, 
  // { value: 'MR_STATUS', label: 'MR Status'}
]

export const HIDDEN_REPORT_STATUS = {
  'VISIBLE': 'VISIBLE',
  'ARCHIVED': 'ARCHIVED'
}
 
 export const UNIQUE_UPLOAD_PROPERTIES = [
   "erp_ref"
 ]
  
export const REFERENCE_SCREEN_SIZE = 1040

export const FEATURE = {
  RECEIPT_NOTES: 'receipt-notes',
  RECEIVING_INSPECTION: 'receiving-inspection',
  MATERIAL_REQUISITION: 'material-requisition',
  DELIVERY_NOTES: 'delivery-notes',
  RIG_RETURN: 'rig-return',
  BACKLOAD_MANIFEST: 'backload-manifest',
  PERIODICAL_INSPECTION: 'periodical-inspection',
  MODIFIED_PRODUCTS: 'modified-product',
  PIPE_AGE: 'pipe-age', 
  PIPE_AGE_CUSTOMER_REPORT: 'pipe-age-customer-report',
  DISPATCH: 'dispatch'
}

export const PIPE_DATA_SLUGS = {
  GET_PIPE_LIST: 'GET_PIPE_LIST',
  GET_NOMINAL_DATA: 'GET_NOMINAL_DATA', 
  GET_QUALITY_CERTIFICATE: 'GET_QUALITY_CERTIFICATE', 
  GET_ACTUAL_DATA_COUNT_FROM_FILTERS: 'GET_ACTUAL_DATA_COUNT_FROM_FILTERS'  
}
