import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiEndUsers from 'api/end-users'
import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon } from 'components/common'

import ExportModal from 'containers/common/ExportModal'
import UnitSelect from 'containers/common/UnitSelect'
import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { ExportContext } from 'stores/ExportStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE, INTERNAL_REPORTS_TYPE } from 'utils/constants'

import FiltersModal from 'containers/common/FiltersModal'
import FilterDisplay from 'containers/common/FilterDisplay'
import { FilterContext } from 'stores/FilterStore'
import TableGrouped from 'components/common/TableGrouped'
import Cookies from 'js-cookie'

import { Select } from 'components/form'

const formatReports = reports => _.chain(reports)
  .orderBy('entranceDate', 'desc')
  .orderBy('partNumber','asc')
  .value()

const ReceiptNotesPage = ({ stockKey }) => {
  const { t } = useTranslation()

  const { setPageSubtitle, lengthUnit, weightUnit, setPageLoading, 
          convertToCurrentLengthUnit, convertToCurrentWeightUnit, 
          formatNumberByCulture } = useContext(AppContext)
  
  const { warehouses } = useContext(WarehouseContext)
  const { idToken } = useContext(UserContext)
  const { filteredData: {filteredTableData }, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])
  
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)

  const [endUser, setEndUser] = useState(undefined)
  const [ endUsers, setEndUsers ] = useState([])

  const toggleExportModal = () => setShowExportModal(currentState => !currentState)
  
  const { setIsPdf } = useContext(ExportContext) 

  useEffect(() => {
    if (stock) {
      Cookies.set('_currentWarehouse', stockKey)
      setPageSubtitle(stock?.title+ ' - ' + t('Receipt Notes'))
    }
  }, [stock, setPageSubtitle, t, stockKey])

  const inputEndUsers = useMemo(() => _.orderBy(_.uniqBy(endUsers, 'dsc_client'), 'label', 'asc').filter(item => item.wid === stock.wid).map(({ id_global, dsc_client }) => ({
    value: id_global,
    label: dsc_client
  })), [endUsers, stock])
  
  useEffect(() => {
    if(inputEndUsers  && inputEndUsers.length > 0){
      if(!endUser)
        setEndUser(inputEndUsers[0].value)
    }
  }, [endUser, inputEndUsers])

  useEffect(() => {
    async function fetchData(){
        try {
            if(stock){
                setPageLoading(true)
                const response = await apiEndUsers.getAvailableEndUsers(stock?.wid, idToken)
                if(response){
                    setEndUsers(response.endUsers)
                }
            }  
        }
        catch(error){
            toast.error(error.message)
        }
    }
    fetchData() 
  }, [stock]) // eslint-disable-line react-hooks/exhaustive-deps

  const downloadReport = useCallback(async report => {
    setLoading(true)
    try {
      setLoading(true)
      const params = { reportId: report.id,partNumber: report.partNumber?? null,  reportType: FEATURE.RECEIPT_NOTES }
      const result = await apiExternalAdapters.getReportFromReporter(params, idToken )
      const link = document.createElement('a')
      link.href = result
      link.target = '_blank'
      link.click()
      setLoading(false)
    }
    catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }, [idToken])

  const onDownloadClick = useCallback(beid => () => {
    downloadReport(beid)
  }, [downloadReport])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        if(stock && endUser){
          const data = await apiReports.getReceiptNotesList({ wid: stock.wid, endUserId: endUser  }, idToken)
          setData(formatReports(data))
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [ stock, endUser, idToken, setData ]) 

  const data = useMemo(() => (
    _.chain(filteredTableData)
      .groupBy((item)=>`${item.id}-${item.ownership}`)
      .map((value) => ({
        id: value[0].id,
        customerOrder: value[0].customerOrder,
        customerItem: value[0].customerItem,
        salesOrder: value[0].salesOrder,
        salesItem: value[0].salesItem,
        entranceDate: value[0].entranceDate,
        erpReference: value[0].erpReference,
        materialDescription: value[0].materialDescription,

        totalLength: _.sumBy(value, ({totalLength}) => Number(totalLength)),
        pieces: _.sumBy(value, ({pieces}) => Number(pieces)),
        totalWeight: _.sumBy(value, ({totalWeight}) => Number(totalWeight)),
        
        children: value || []
      }))
      .orderBy('id', 'desc')
      .value()
), [filteredTableData])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      Header: t('ID'),
      label: t('ID'),
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      },
      Cell: ({ cell: { value, row } }) => (
        <FlexView
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="13px"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 32}px`,
              cursor: row.canExpand ? 'pointer' : 'inherit'
            },
            title: ''
          })}
        >
          {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'} />}
          {value === 'null' ? t('Not Found') : value}
        </FlexView>
      ),
    },
    {
      Header: t('Part Number'),
      accessor: 'partNumber',
      label: t('Part Number'),
      customCellProps: {
        style: {
          textAlign: 'center',
          minWidth: '10px',
        },
      }, 
      show: true,
      filterModalEnabled: false,
    },
    {
      Header: t('CUSTOMER PO#'),
      accessor: 'customerOrder',
      label: t('CUSTOMER PO#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('CUSTOMER ITEM#'),
      accessor: 'customerItem',
      label: t('CUSTOMER ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES PO#'),
      accessor: 'salesOrder',
      label: t('SALES PO#'),
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('SALES ITEM#'),
      accessor: 'salesItem',
      label: t('SALES ITEM#'),
      customHeaderProps: {
        style: {
          minWidth: '60px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Entrance Date'),
      label: t('Entrance Date'),
      accessor: 'entranceDate',
      sortType: 'date',
      customHeaderProps: {
        style: {
          minWidth: '120px'
        }
      }
    },
    {
      Header: t('ERP REFERENCE'),
      label: t('ERP REFERENCE'),
      accessor: 'erpReference',
      customHeaderProps: {
        style: {
          minWidth: '80px'
        }
      },
    },
    {
      Header: t('Material Description'),
      accessor: 'materialDescription',
      label: t('Material Description'),
      customHeaderProps: {
        style: {
          minWidth: '250px'
        }
      },
    },
    {
      Header: `${t('Pieces')}`,
      label: t('Pieces'),
      accessor: 'pieces',
      Cell: (props) => {
        const { pieces } = props.row.original
        return `${formatNumberByCulture(pieces, 0)}`
      },
      customHeaderProps: {
        style: {
          minWidth: '40px'
        }
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: t('Total Length') + ` (${lengthUnit})`,
      label: t('Total Length') + ` (${lengthUnit})`,
      id: 'length',
      accessor: 'totalLength',
      Cell: (props) => {
        const { totalLength } = props.row.original
        return `${convertToCurrentLengthUnit((totalLength/1000), (totalLength/304.8), lengthUnit)}`
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      Header: `${t('Weight') + ` (${weightUnit})`}`,
      label: `${t('Weight') + ` (${weightUnit})`}`,
      id: 'weight',
      accessor: 'totalWeight',
      Cell: (props) => {
        const { totalWeight } = props.row.original
        return `${convertToCurrentWeightUnit(totalWeight, 'kg')}`
      },
      customCellProps: {
        style: {
          textAlign: 'center'
        }
      }
    },
    {
      id: 'download',
      Header: t('Download'),
      disableFilters: true,
      customHeaderProps: {
        style: {
          minWidth: '32px'
        }
      },
      Cell: ({ cell: { row } }) => <FlexView alignItems="center" justifyContent="center" width="100%">
        <Icon name="download" width="24px" height="24px" onClick={onDownloadClick(row.original)} />
      </FlexView>
    }
  ], [t, onDownloadClick, weightUnit, lengthUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, formatNumberByCulture])

  const onExcelExportClick = () => {
    setShowExportModal(prev => !prev)
  }

  const onPdfExportClick = () => {
    setIsPdf(true)
    setShowExportModal(prev => !prev)
  }

  const toggleModal = () => setShowModal(currentState => !currentState)

  return <FlexView flex="1" position="relative" alignSelf="stretch">
    <StockTabs stock={stock} />
    <FlexView margin="16px 16px 8px" flexDirection="row" justifyContent="space-between" alignSelf="stretch">
      { 
        endUsers.length > 1 ?
        <Select margin="0px" value={endUser} options={inputEndUsers} onChange={setEndUser} placeHolder={endUser ? endUser : t('Select an option')} /> : null
      }
      <FilterDisplay options={columns} onTagClick={toggleModal} />
      <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" flex="1">
        <UnitSelect />
        <Icon name="filter" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Filter')} onClick={toggleModal}/>
        <Icon name="xls" width="28px" height="28px" margin="0px 8px 0px 0px" tooltip={t('Export to Excel')} onClick={onExcelExportClick} />
        <Icon name="pdf" width="28px" height="28px" margin="0px 0px 0px 0px" tooltip={t('Export to PDF')} onClick={onPdfExportClick} />
      </FlexView>
    </FlexView>
    <LoadingOverlay visible={loading} />
    
    <TableGrouped columns={columns} data={data} quantityLabel='Items'/>
    <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data}/>
    <ExportModal title={t('Extract Receipt Report') } label={t('Choose the search period')+':'} type={INTERNAL_REPORTS_TYPE.RECEIPT } isOpen={showExportModal} onOutsideClick={toggleExportModal} />
  </FlexView>
}

export default ReceiptNotesPage